<template>
  <div>
    <div class="coin-list d-flex flex-column">
      <div class="header">
        <h1 class="title mb-0">{{ $t('lk.header.coinSettings') }}</h1>
        <CLoadingButtonCustom
          class="cbtn shadow-none"
          color="success"
          :loading="saveChangeLoad"
          :disabled="saveChangeLoad"
          @click.native="saveChange"
        >
          <CIcon name="cil-save"/>
          <span class="ml-2">{{ $t('general.saveChanges') }}</span>
        </CLoadingButtonCustom>
      </div>
    </div>
    <CCard color="second-card-bg" class="ccard shadow-none overflow-hidden">
      <CCardHeader class="ccard-header">{{ $t('lk.coinList.coinsToDisplayInScreener') }}</CCardHeader>
      <CCardBody class="ccard-body overflow-auto">
        <template v-if="coinArray.length && !getCoinLoad">
          <CRow class="row-cols-2 row-cols-sm-4 row-cols-xl-6">
            <CCol v-for="(item, i) in coinArray"
                  :key="i"
                  class="coin-item"
            >
              <h5 class="mb-0">{{ item.symbol }}</h5>
              <CInputCheckbox
                :disabled="item.base === 'BTC'"
                :checked.sync="item.enable_import"
                custom
                :label="$t('lk.coinList.import')"
              />
              <!--CInputCheckbox
                :disabled="item.base === 'BTC'"
                :checked.sync="item.enable_tv_markets"
                custom
                label="маркет"
              /-->
              <CInputCheckbox
                :checked.sync="item.enable_screener"
                custom
                :label="$t('lk.coinList.screener')"
              />
              <CInputCheckbox
                :checked.sync="item.is_futures"
                custom
                label="futures"
              />
            </CCol>
          </CRow>
        </template>

        <div
          v-if="getCoinLoad"
          class="spinner-wrap"
        >
          <CSpinner
            color="primary"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ScreenerCoinList",
  data() {
    return {
      coinArray: [],
      saveChangeLoad: false,
      getCoinLoad: false
    }
  },
  created() {
    this.getCoin()
  },
  methods: {
    // todo необходимо для установки списка фьючерсов, после установки- убрать
    showIsFutures() {
      console.log(this.coinArray.filter(a => a.is_futures))
    },
    // todo необходимо для установки списка фьючерсов, после установки- убрать
    setIsFutures() {
      const list = [
        '1INCHUSDT',
        'DEFIUSDT',
        'ANKRUSDT',
        'KNCUSDT',
        'BTCDOMUSDT',
        'HOTUSDT',
        'ZILUSDT',
        'LTCUSDT',
        'BATUSDT',
        'REEFUSDT',
        'AKROUSDT',
        'ONTUSDT',
        'TRBUSDT',
        'BELUSDT',
        'ATOMUSDT',
        'RENUSDT',
        'ADAUSDT',
        'LRCUSDT',
        'BTTUSDT',
        'DOGEUSDT',
        'SOLUSDT',
        'DOTUSDT',
        'NKNUSDT',
        'STMXUSDT',
        'XEMUSDT',
        'CXUSDT',
        'SUSHIUSDT',
        'DASHUSDT',
        'OTAUSDT',
        'OSTUSDT',
        'MKRUSDT',
        'WAVESUSDT',
        'TLMUSDT',
        'CRVUSDT',
        'EOSUSDT',
        'NEARUSDT',
        'ZENUSDT',
        'YFIIUSDT',
        'LINKUSDT',
        'UNFIUSDT',
        'BANDUSDT',
        'TRXUSDT',
        'DENTUSDT',
        'RSRUSDT',
        'EGLDUSDT',
        'C98USDT',
        'SANDUSDT',
        'ALPHAUSDT',
        'LUNAUSDT',
        'MANAUSDT',
        'GTCUSDT',
        'MATICUSDT',
        'KEEPUSDT',
        'XMRUSDT',
        'ALICEUSDT',
        'OMGUSDT',
        'OTXUSDT',
        'KAVAUSDT',
        'BTCUSDT',
        'AAVEUSDT',
        'ONEUSDT',
        'ALGOUSDT',
        'COMPUSDT',
        'YFIUSDT',
        'XRPUSDT',
        'GRTUSDT',
        'BNBUSDT',
        'RVNUSDT',
        'ATAUSDT',
        'VETUSDT',
        'SXPUSDT',
        'CHRUSDT',
        'ZRXUSDT',
        'DYDXUSDT',
        'DODOUSDT',
        'BTSUSDT',
        'MASKUSDT',
        'LITUSDT',
        'SKLUSDT',
        '1000XECUSDT',
        'MTLUSDT',
        'BLZUSDT',
        'HBARUSDT',
        'COTIUSDT',
        'KSMUSDT',
        'HNTUSDT',
        'QTUMUSDT',
        'BAKEUSDT',
        'FILUSDT',
        'OCEANUSDT',
        'FTMUSDT',
        'BZRXUSDT',
        'NEOUSDT',
        'SNXUSDT',
        'RLCUSDT',
        'AUDIOUSDT',
        'THETAUSDT',
        'CVCUSDT',
        'CELRUSDT',
        'STORJUSDT',
        'FLMUSDT',
        'ETHUSDT',
        'ZECUSDT',
        'SFPUSDT',
        'UNIUSDT',
        'ENJUSDT',
        'GALAUSDT',
        'ETCUSDT',
        'CTKUSDT',
        'XTZUSDT',
        'SCUSDT',
        'SHIBUSDT',
        'RAYUSDT',
        'RUNEUSDT',
        'XLMUSDT',
        'CPUSDT',
        'SRMUSDT',
        'LINAUSDT',
        'CHZUSDT',
        'BALUSDT',
        'OGNUSDT',
        'AXSUSDT',
        'AVAXUSDT',
        'TOMOUSDT',
        'BCHUSDT',
      ]
      const coinArrayLabels = this.coinArray.map(a => a.symbol)
      console.log(list)
      list.forEach(a => {
        const index = coinArrayLabels.indexOf(a)
        if (index >= 0) {
          console.log('содержит', this.coinArray[index].symbol)
          this.coinArray[index].is_futures = true
        } else {
          console.warn('не содержит', a)
        }
      })
    },
    getCoin() {
      this.getCoinLoad = true
      axios.get('/api/v1/pairs')
        .then(res => {
          const pairs = res?.data?.data
          if (Array.isArray(pairs)) {
            this.coinArray = pairs
          }
        })
        .finally(() => {
          this.getCoinLoad = false
        })
    },
    saveChange() {
      const data = this.coinArray.map(({id, enable_import, enable_screener, is_futures}) => ({
        id,
        enable_import,
        enable_screener,
        is_futures
      }))

      this.saveChangeLoad = true
      axios.put('/api/v1/pairs', {
        pairs: data
      })
        .then(res => {
          const status = res?.status
          if (status < 300) {
            this.$toastr.success(this.$t('lk.coinList.coinSettingsUpdated'))
            this.coinArray = []
          }
        })
        .finally(() => {
          this.getCoin()
          this.saveChangeLoad = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .cbtn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include media-breakpoint-down(xs) {
    margin-bottom: 1.25rem;

    .title {
      display: none;
    }
    .cbtn {
      width: 100%;
    }
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 1.75rem;

    .title {
      font-size: 1.25rem;
    }
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 2.25rem;

    .title {
      font-size: 2.25rem;
    }
  }
}

.ccard {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .ccard-body {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  @include media-breakpoint-down(xs) {
    margin: 0 -1.25rem !important;

    .ccard-body {
      padding: 1rem 1.75rem;
    }
  }
  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.25rem 1.75rem;
    }
  }
  @include media-breakpoint-down(lg) {
    margin: 0 -2rem;

    .ccard-header {
      display: none;
    }
  }
  @include media-breakpoint-up(xl) {
    .ccard-header {
      padding: 1.25rem 2rem;
    }
    .ccard-body {
      max-height: 33rem;
    }
  }
}

.coin-item {
  //width: calc((100% / 6) - 1rem);
  margin-bottom: 1rem;
}

.spinner-wrap {
  margin: auto;
}

.coin-list {
  @include media-breakpoint-down(lg) {
    @media (min-height: 400px) {
      height: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    @media (min-height: 600px) {
      height: 100%;
    }
  }
}
</style>
